<template>
    <div class="vote-page">
        <v-container fluid class="vote-container pa-4 pa-sm-6 pa-md-8">
            <v-row justify="center">
                <v-col cols="12" sm="10" md="8" lg="8" xl="6">
                    <v-card class="mb-2" elevation="2">
                        <v-card-title class="d-flex justify-space-between align-center">
                            <span class="text-body-1 font-weight-bold">{{ getPageTitle() }}</span>
                            <v-btn color="primary" @click="submit" small>保存</v-btn>
                        </v-card-title>
                    </v-card>
                    <div class="d-flex rank-columns">
                        <div class="rank-column flex-grow-1 mr-1">
                            <v-card class="rank-card" elevation="3">
                                <v-card-title class="text-subtitle-2 font-weight-bold green--text">
                                    <v-icon small color="green" class="mr-1">mdi-trophy</v-icon>
                                    我的排名
                                </v-card-title>
                                <v-card-text class="rank-list pa-0">
                                    <draggable v-model="leftList" group="people" :move="checkMove">
                                        <v-list-item v-for="(item, index) in leftList" :key="'left-' + index" @click="moveToRight(index)" class="rank-item" :dense="$vuetify.breakpoint.smAndDown">
                                            <v-list-item-icon class="mr-0 rank-number-container">
                                                <span class="rank-number">{{ index + 1 }}</span>
                                            </v-list-item-icon>
                                            <v-list-item-avatar :size="$vuetify.breakpoint.smAndDown ? 24 : 40" class="ml-3 mr-3">
                                                <v-img :src="getAvatarUrl(item)" :alt="item"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title :class="{'text-caption': $vuetify.breakpoint.smAndDown, 'text-subtitle-1': $vuetify.breakpoint.mdAndUp}">{{ item }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </draggable>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="rank-column flex-grow-1 ml-1">
                            <v-card class="rank-card" elevation="3">
                                <v-card-title class="text-subtitle-2 font-weight-bold pink--text">
                                    <v-icon small color="pink" class="mr-1">mdi-account-multiple</v-icon>
                                    待排人物
                                </v-card-title>
                                <v-card-text class="rank-list pa-0">
                                    <draggable v-model="rightList" group="people" :move="checkMove">
                                        <v-list-item v-for="(item, index) in rightList" :key="'right-' + index" @click="moveToLeft(index)" class="rank-item" :dense="$vuetify.breakpoint.smAndDown">
                                            <v-list-item-icon class="mr-0 rank-number-container">
                                                <span class="rank-number rank-number-right">?</span>
                                            </v-list-item-icon>
                                            <v-list-item-avatar :size="$vuetify.breakpoint.smAndDown ? 24 : 40" class="ml-3 mr-3">
                                                <v-img :src="getAvatarUrl(item)" :alt="item"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title :class="{'text-caption': $vuetify.breakpoint.smAndDown, 'text-subtitle-1': $vuetify.breakpoint.mdAndUp}">{{ item }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </draggable>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar v-model="snackbar" color="success" :timeout="3000">
            保存成功！
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false" x-small>关闭</v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="errorSnackbar" color="error" :timeout="3000">
            {{ errorMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="errorSnackbar = false" x-small>关闭</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import axios from 'axios';

export default {
    name: "Vote",
    components: {
        draggable
    },
    data() {
        return {
            snackbar: false,
            errorSnackbar: false,
            errorMessage: '',
            leftList: [],
            rightList: [],
            dragItem: null,
            dragList: null,
            currentTopic: '',
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            const topic = this.$route.params.topic;
            this.currentTopic = topic;

            const token = localStorage.getItem('token');
            if (token) {
                axios.get('https://qidafang.com/wikiapi/vote', { params: { topic: topic, token: token } }).then(response => {
                    this.leftList = response.data.tops ? response.data.tops.split(',') : [];
                    this.rightList = response.data.lefts ? response.data.lefts.split(',') : [];

                    if (!response.data.tops) {
                        this.rightList = this.rightList.sort(() => Math.random() - 0.5);
                    }
                });
            }
        },
        getAvatarUrl(item) {
            if (this.currentTopic.startsWith('water')) {
                return `https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/water/1/${item}.jpg`;
            } else {
                return `https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/11/${item}.jpg`;
            }
        },
        checkMove(event) {
            return event.related.className !== 'non-draggable';
        },
        moveToRight(index) {
            this.rightList.push(this.leftList[index]);
            this.leftList.splice(index, 1);
        },
        moveToLeft(index) {
            this.leftList.push(this.rightList[index]);
            this.rightList.splice(index, 1);
        },
        submit() {
            const token = localStorage.getItem('token');
            const topic = this.$route.params.topic;
            if (token) {
                axios.post('https://qidafang.com/wikiapi/vote', null, {
                    params: {
                        topic: topic,
                        token: token,
                        lefts: this.rightList.join(','),
                        tops: this.leftList.join(',')
                    }
                }).then(() => {
                    this.snackbar = true;
                }).catch((error) => {
                    this.errorMessage = "投票统计阶段无法保存排名";
                    this.errorSnackbar = true;
                });
            }
        },
        getPageTitle() {
            const topicTitles = {
                'water_force_heros': '梁山武评',
                'water_force': '水浒武评',
                'intelligence': '演义智评',
                'warrior_intelligence': '武将智评'
            };
            return topicTitles[this.currentTopic] || '排名评选';
        }
    },
    watch: {
        '$route.params.topic': {
            handler(newTopic, oldTopic) {
                if (newTopic !== oldTopic) {
                    this.fetchData();
                }
            },
            immediate: true
        }
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.topic !== from.params.topic) {
            this.fetchData();
        }
        next();
    }
};
</script>

<style scoped>
.vote-page {
    min-height: calc(100vh - 100px); /* 调整为顶部导航栏和页脚的总高度 */
    display: flex;
    flex-direction: column;
}

.vote-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

.rank-columns {
    flex-grow: 1;
    display: flex;
}

.rank-column {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.rank-card {
    display: flex;
    flex-direction: column;
    height: 100%; /* 使卡片占满列高度 */
}

.rank-list {
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(10 * 56px + 1px); /* 桌面端：10个条目的高度（每个56px）加1px边框 */
    min-height: 300px; /* 设置最小高度，确保小屏幕上有足够的拖拽空间 */
}

/* 确保拖拽区域至少占满列表高度 */
.rank-list > div {
    min-height: 100%;
}

.rank-item {
    cursor: pointer;
    transition: background-color 0.3s;
}

.rank-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* 自定义滚动条样式 */
.rank-list::-webkit-scrollbar {
    width: 16px; /* 保持整体宽度不变 */
}

.rank-list::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 8px;
}

.rank-list::-webkit-scrollbar-thumb {
    background: #4CAF50;
    border: 3px solid #e0e0e0; /* 增加边框宽度，使滑块稍微变窄 */
    border-radius: 8px;
}

.rank-list::-webkit-scrollbar-thumb:hover {
    background: #45a049;
}

/* 为右侧榜单添加特定的滚动条样式 */
.rank-column:last-child .rank-list::-webkit-scrollbar-thumb {
    background: #E91E63;
}

.rank-column:last-child .rank-list::-webkit-scrollbar-thumb:hover {
    background: #d81b60;
}

/* 移动端样式调整 */
@media (max-width: 600px) {
    .rank-list::-webkit-scrollbar {
        width: 20px; /* 保持移动端宽度不变 */
    }

    .rank-list::-webkit-scrollbar-thumb {
        border: 4px solid #e0e0e0; /* 移动端也稍微增加边框宽度 */
    }
}

.v-list-item__avatar {
    margin-right: 16px !important;
}

@media (max-width: 600px) {
    .v-list-item__avatar {
        margin-right: 8px !important;
    }
}

.v-card__title {
    padding: 8px 16px;
}

.v-card__text {
    padding: 0;
}

.v-list-item {
    padding: 0 16px;
    height: 56px; /* 桌面端列表项高度 */
}

/* 移动端样式调整 */
@media (max-width: 600px) {
    .rank-list {
        max-height: calc(10 * 48px + 1px); /* 移动端：10个条目的高度（每个48px）加1px边框 */
    }

    .v-list-item {
        height: 48px; /* 移动端列表项高度 */
    }
}

.v-list-item__icon {
  margin-right: 8px !important; /* 减少排名序号与头像之间的距离 */
}

.v-list-item__avatar {
  margin-right: 12px !important; /* 保持头像与姓名之间的距离 */
}

/* 移动端样式调整 */
@media (max-width: 600px) {
  .v-list-item__icon {
    margin-right: 6px !important; /* 移动端进一步减少间距 */
  }

  .v-list-item__avatar {
    margin-right: 8px !important; /* 移动端稍微减少头像与姓名之间的距离 */
  }
}

.rank-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-right: 8px !important;
}

.rank-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: #9e9e9e; /* 默认颜色，用于左侧榜单 */
}

/* 右侧列表（待排人物）的序号样式 */
.rank-column:last-child .rank-number {
  background-color: #E91E63; /* 使用与标题相同的粉色，适用于所有待排人物 */
}

/* 左侧榜单的特殊样式 */
.rank-column:first-child .rank-item:nth-child(1) .rank-number {
  background-color: #ffd700; /* 金色 */
}

.rank-column:first-child .rank-item:nth-child(2) .rank-number {
  background-color: #c0c0c0; /* 银色 */
}

.rank-column:first-child .rank-item:nth-child(3) .rank-number {
  background-color: #cd7f32; /* 铜色 */
}

/* 4到10名样式 */
.rank-column:first-child .rank-item:nth-child(n+4):nth-child(-n+10) .rank-number {
  background-color: #4caf50; /* 绿色，用于4-10名 */
}

/* 移动端样式调整 */
@media (max-width: 600px) {
  .rank-number-container {
    width: 24px;
    height: 24px;
    margin-right: 6px !important;
  }

  .rank-number {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}

/* 调整排名序号和头像之间的距离 */
.rank-number-container {
  margin-right: 8px !important; /* 减少排名序号与头像之间的距离 */
}

.v-list-item__avatar {
  margin-left: 0 !important; /* 移除头像左侧的默认边距 */
  margin-right: 12px !important; /* 保持头像与姓名之间的距离 */
}

/* 移动端样式调整 */
@media (max-width: 600px) {
  .rank-number-container {
    margin-right: 6px !important; /* 移动端进一步减少间距 */
  }

  .v-list-item__avatar {
    margin-right: 8px !important; /* 移动端稍微减少头像与姓名之间的距离 */
  }
}

/* 右侧列表（待排人物）的序号样式 */
.rank-number-right {
  background-color: #E91E63; /* 使用与标题相同的粉色 */
}
</style>